<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component
              class="mt-5"
              :is="item.component"
              v-on:profissao-id="storeProfissaoId($event)"
              :profissao-id="profissaoId"
            />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosColaborador from './DadosColaborador.vue'
import EspecializacoesColaborador from './EspecializacoesColaborador.vue'

export default {
  components: {
    DadosColaborador,
    EspecializacoesColaborador
  },

  data() {
    return {
      tab: null,
      profissaoId: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-detalhes-colaborador',
          component: 'DadosColaborador'
        },
        {
          tab: 'Especializações',
          content: 'tab-especializacoes-colaborador',
          component: 'EspecializacoesColaborador'
        }
      ]
    }
  },

  methods: {
    storeProfissaoId(emmitedId) {
      this.profissaoId = emmitedId
    }
  }
}
</script>

<style scoped>
.ajuste {
  width: 80%;
}
</style>
