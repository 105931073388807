import axios from  'axios';

const URL_PRESTADOR = '/api-prestador';

const atribuirParametros = (filtro) => {
  let parametros = [];
  for (const [key, value] of Object.entries(filtro)) {
    if(value || value === 0) parametros.push(`${key}=${value}`);
  }

  if(parametros.length <= 0) return '';

  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`;
}

const paramInstituicao = '&instituicao=1'

export default {
  filtroParceiro(filtro) {
    const parametros = atribuirParametros(filtro) + paramInstituicao;
    const url = `${URL_PRESTADOR}/vendedor${parametros}`;
    return axios.get(url)
  },

  parceiroPorId(parceiroId) {
    const url = `${URL_PRESTADOR}/vendedor/${parceiroId}/detalhar`;
    return axios.get(url);
  },

  atualizarParceiro(parceiroId, body) {
    const url = `${URL_PRESTADOR}/vendedor/${parceiroId}/atualizar`;
    return axios.put(url, body);
  },

  promocaoDoParceiro(parceiroId) {
    const url = `${URL_PRESTADOR}/vendedor/${parceiroId}/imagemPromocional`;
    return axios.get(url);
  },

  documentosParceiro(parceiroId) {
    const url = `${URL_PRESTADOR}/vendedor/${parceiroId}/arquivos`
    return axios.get(url);
  },

  statusParceiro() {
    const url = `${URL_PRESTADOR}/vendedor/status`
    return axios.get(url);
  },

  alterarStatusParceiro(body) {
    const url = `${URL_PRESTADOR}/vendedor/status`;
    return axios.put(url, body);
  },

  sociosPorParceiro(parceiroId) {
    const url = `${URL_PRESTADOR}/socio/vendedor/${parceiroId}`;
    return axios.get(url);
  },

  alterarPermisaoVendaProdutos(body) {
    const url = `${URL_PRESTADOR}/usuario/perfil/produto`;
    return axios.put(url, body);
  },

  areasAtuacaoNaoVinculadasPorParceiro(parceiroId) {
    const url = `${URL_PRESTADOR}/vendedor/${parceiroId}/area/naoVinculado`;
    return axios.get(url);
  },

  areasAtuacaoVinculadasAoParceiro(parceiroId) {
    const url = `${URL_PRESTADOR}/vendedor/${parceiroId}/area/vinculado`;
    return axios.get(url);
  },

  vincularAreaParceiro(body) {
    const url = `${URL_PRESTADOR}/vendedor/area`;
    return axios.post(url, body);
  },

  atualizarStatusVinculoArea(body) {
    const url = `${URL_PRESTADOR}/vendedor/area/atualizar`;
    return axios.put(url, body, { responseType: 'text' });
  },

  cadastrarSocio(body) {
    const url = `${URL_PRESTADOR}/socio`;
    return axios.post(url, body);
  },

  editarSocio(socioId, socio){
    const url = `${URL_PRESTADOR}/socio/${socioId}/atualizar`;
    return axios.put(url, socio);
  },

  tiposDocumentosSocio() {
    const url = `${URL_PRESTADOR}/socio/tipoArquivo`;
    return axios.get(url);
  },

  tiposDocumentosParceiro() {
    const url = `${URL_PRESTADOR}/vendedor/tipo/arquivo`;
    return axios.get(url);
  },

  cadastrarDocumentoSocio(documento){
    const url = `${URL_PRESTADOR}/socio/arquivo/cadastrar`;
    return axios.post(url, documento, { responseType: 'text' });
  },

  cadastrarDocumentosParceiro(documento) {
    const url = `${URL_PRESTADOR}/vendedor/arquivo/cadastrar`;
    return axios.post(url, documento, { responseType: 'text' });
  },

  atualizarPromocaoParceiro(arquivoId, body) {
    const url = `${URL_PRESTADOR}/vendedor/vantagens/arquivo/${arquivoId}`;
    return axios.put(url, body);
  }
}
